import React from 'react';
import ApproachPoint from './approachpoint';
import Bulb from '../images/approach/bulb.svg';
import Chat from '../images/approach/chat.svg';
import Star from '../images/approach/star.svg';
import Stopwatch from '../images/approach/stopwatch.svg';
import { MobileRule, TabletRule } from '../constants';

class Approach extends React.Component {
  render() {
    return (
      <section
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#E35E57',
          padding: '8rem 2rem 9rem',
          [MobileRule]: { paddingTop: '5em' },
        }}
      >
        <h3
          css={{
            fontSize: '35px',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            marginBottom: 0,
            [MobileRule]: { fontSize: '25px' },
          }}
        >
          Our approach
        </h3>
        <span
          css={{
            color: '#FFFFFF',
            margin: '2.5rem 0',
            fontWeight: 500,
            fontSize: '35px',
            [MobileRule]: { fontSize: '25px' },
          }}
        >
          /
        </span>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gap: '6rem',
            maxWidth: '1400px',
            margin: '0 auto',
            [TabletRule]: {
              gap: '4rem',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            },
            [MobileRule]: {
              gap: '4rem',
              gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
            },
          }}
        >
          <ApproachPoint
            logo={Bulb}
            title={
              <>
                Meaningful
                <br />
                innovations
              </>
            }
            content="We work with founders who are passionate about creating things that enrich people’s lives and have a positive impact on the world around us."
          />
          <ApproachPoint
            logo={Chat}
            title={
              <>
                True
                <br />
                partnership
              </>
            }
            content="From providing capital to strategic advice, network, and mentorship, we work side by side with our founders and tailor our support to their evolving needs."
          />
          <ApproachPoint
            logo={Stopwatch}
            title={
              <>
                Long-term
                <br />
                commitment
              </>
            }
            content="Building greatness takes time and dedication. We support our founders from idea to execution and beyond, and through all the bumps along the way."
          />
          <ApproachPoint
            logo={Star}
            title={
              <>
                Success beyond
                <br />
                the bottom line
              </>
            }
            content="To us, success is more than financial profit - it is also about creating positive environmental impact, employee wellbeing and customer satisfaction."
          />
        </div>
      </section>
    );
  }
}

export default Approach;
