import React from 'react';
import SocialIcons from './social-icons';
import { MobileRule, TabletRule } from '../constants';

class Footer extends React.Component {
  render() {
    return (
      <footer
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '8rem 2rem 9rem',
          [MobileRule]: { paddingTop: '5em' },
        }}
      >
        <h3
          css={{
            fontSize: '35px',
            textTransform: 'uppercase',
            color: '#E03122',
            marginBottom: 0,
            [MobileRule]: { fontSize: '25px' },
          }}
        >
          Contact
        </h3>
        <span
          css={{
            color: '#E03122',
            margin: '2.5rem 0',
            fontWeight: 500,
            fontSize: '35px',
            [MobileRule]: { fontSize: '25px' },
          }}
        >
          /
        </span>
        <div
          css={{
            display: 'flex',
            gap: '5rem',
            margin: '0 auto',
            [TabletRule]: { gap: '2rem' },
            [MobileRule]: { flexDirection: 'column', gap: '3.75rem' },
          }}
        >
          <div css={{ width: '280px', textAlign: 'center' }}>
            <h4
              css={{
                textTransform: 'uppercase',
                color: '#E03122',
                fontSize: '25px',
                lineHeight: '30px',
                [MobileRule]: { fontSize: '20px', lineHeight: '25px' },
              }}
            >
              Office
              <br />
              Stockholm
            </h4>
            <p
              css={{
                fontSize: '20px',
                margin: 0,
                [MobileRule]: {
                  fontSize: '15px',
                },
              }}
            >
              Red Six AB
              <br />
              QuickOffice
              <br />
              Drottninggatan 86
              <br />
              111 36 Stockholm
              <br />
              Sweden
            </p>
          </div>
          <div css={{ width: '280px', textAlign: 'center' }}>
            <h4
              css={{
                textTransform: 'uppercase',
                color: '#E03122',
                fontSize: '25px',
                lineHeight: '30px',
                marginBottom: '1.25rem',
                [MobileRule]: {
                  fontSize: '20px',
                  lineHeight: '25px',
                  marginBottom: '1.75rem',
                },
              }}
            >
              Office
              <br />
              Värmdö
            </h4>
            <p
              css={{
                fontSize: '20px',
                margin: 0,
                [MobileRule]: {
                  fontSize: '15px',
                },
              }}
            >
              Red Six AB
              <br />
              Skärgårdsvägen 158
              <br />
              139 36 Värmdö
              <br />
              Sweden
            </p>
          </div>
        </div>
        <SocialIcons />
      </footer>
    );
  }
}
export default Footer;
