import React from 'react';
import { MobileRule } from '../constants';

class SocialIcons extends React.Component {
  render() {
    return (
      <div
        css={{
          marginTop: '2.5rem',
          [MobileRule]: {
            marginTop: '4rem',
          },
        }}
      >
        <a
          css={{ margin: 0 }}
          href="https://www.linkedin.com/company/red-six-group/"
        >
          <svg
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            css={{
              display: 'block',
              width: '3rem',
              height: '3rem',
              color: '#E35E57',
            }}
          >
            <path
              d="M240 0h32c1.28.28 2.55.72 3.85.82 39.86 2.98 77.18 14.42 111.18 35.35 66.08 40.68 106.79 99.2 121.05 175.71 1.73 9.29 2.64 18.74 3.93 28.11v32c-.41 3.28-.87 6.56-1.22 9.85-5.48 50.63-23.83 96.06-56.15 135.44-40.19 48.97-91.6 79.39-154.05 90.75-9.46 1.72-19.05 2.66-28.58 3.96h-32c-6.25-.77-12.51-1.44-18.74-2.32-47.23-6.72-89.65-24.71-126.52-55.03-48.95-40.23-79.43-91.57-90.77-154.03C2.26 291.15 1.31 281.54 0 272v-32c.41-3.28.85-6.55 1.21-9.84 5.92-54.23 26.43-102.18 62.6-143.06 39.78-44.95 89.32-72.91 148.58-83.24 9.15-1.6 18.4-2.59 27.61-3.86Zm-23.66 386.74h62.08v-6.1c0-24.66-.03-49.32.02-73.97.02-10.16 0-20.33.39-30.48.42-11.1 5.97-19.67 14.71-26.04 17.84-13 41.59-5.2 47.65 15.93 1.99 6.93 2.67 14.42 2.74 21.67.29 31.15.12 62.31.12 93.47v5.51h62.08c.09-1.86.23-3.48.23-5.11.01-33.49.09-66.98-.05-100.47-.03-7.31-.49-14.66-1.37-21.92-4.42-36.51-26.26-59.53-61.45-63.66-25.47-2.99-47.06 4.66-62.33 26.46-.54.77-1.19 1.47-2.68 3.28v-25.69h-62.12v187.1Zm-96.77-187.41v187.34h62.11V212.48c0-2.83-.23-5.69.05-8.49.39-3.85-1.2-4.77-4.85-4.73-16.33.17-32.66.08-48.99.08h-8.32Zm-3.89-57.69c.12 18.98 14.73 32.41 35.02 32.22 20.42-.19 35.25-13.91 35.06-32.42-.2-19.19-14.87-32.62-35.46-32.43-20.45.18-34.75 13.66-34.63 32.63Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    );
  }
}

export default SocialIcons;
